<template lang="pug">
om-modal.insert-code-help-v2-modal(
  name="insert-code-help-v2"
  :width="800"
  color="light"
  scrollable
  :clickToClose="true"
  @beforeOpen="beforeOpen"
  @opened="onOpened"
  :headerBorder="true"
)
  template(slot="modal-header")
    .brand-modal-action-icon
      close-icon.cursor-pointer(
        @click.native="close('close')"
        :width="14"
        :height="14"
        color="#C2C2C2"
      )
    om-body-text(bt700md) {{ $t('codeInsertV2.titles.connect') }}
  template(slot="modal-body")
    .insert-code-help-v2
      om-accordion
        om-accordion-item(fluid trackPrefix="codeInsert-help-askUs")
          template(#name) {{ $t('codeInsertV2.help.askUsForHelp.title') }}
          template(#icon)
            img(:src="require('@/assets/admin/img/insert-code/chat.png')")
          AskUsContent(ref="askUsContent")
        om-accordion-item(fluid trackPrefix="codeInsert-help-sendToDeveloper")
          template(#name) {{ $t('codeInsertV2.help.sendToDev.title') }}
          template(#icon)
            img(:src="require('@/assets/admin/img/insert-code/email.png')")
          SendToDeveloperContent(ref="sendToDeveloper")
  template(slot="modal-footer")
    .brand-modal-footer-content.d-flex.justify-content-end
      om-button(ghost secondary @click="close('cancel')") {{ $t('cancel') }}
</template>

<script>
  import { track } from '@/services/xray';

  export default {
    components: {
      AskUsContent: () => import('./AskUsContent.vue'),
      SendToDeveloperContent: () => import('./SendToDeveloperContent.vue'),
    },
    data: () => ({ event: null }),
    methods: {
      beforeOpen(event) {
        this.event = event;
      },
      onOpened() {
        this.$refs?.askUsContent?.reset?.(this.event);
        this.$refs?.sendToDeveloper?.reset?.(this.event);
      },
      close(type) {
        this.$modal.hide('insert-code-help-v2');
        track(`codeInsert-help-${type}`, null);
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .insert-code-help-v2
    color: $om-gray-700
    &-modal
      .body-text
        color: $om-gray-700
      .insert-code-start-chat
        margin-top: 1.5rem
      .insert-code-ask-us-illustration
        max-height: 10.5rem
        width: auto
      hr
        margin: 1.25rem 0
      label
        margin-bottom: .25rem
      .form-group
        margin-bottom: .75rem
      .brand-modal-footer
        border-top: 1px solid $om-gray-300
</style>
